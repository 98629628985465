/**
 * attempts to exit fullscreen for this element.
 * if all else fail, request exit on document
 * @param {*} element
 */
export default function (element) {
  try {
    if (element.exitFullscreen) {
      element.exitFullscreen();
    } else if (element.webkitExitFullscreen) {
      /* Safari */
      element.webkitExitFullscreen();
    } else if (element.msExitFullscreen) {
      /* IE11 */
      element.msExitFullscreen();
    }
    throw "unable to exit from element.";
  } catch (error) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }
}
