import { SESSION_TYPES } from "@/constants";
import { useSessionStore } from "@/stores/session";
import { useWatchStore } from "@/stores/watch";
import { useRadioStore } from "@/stores/radio";
import { useGTMStore } from "@/stores/gtm";

const buildGenresList = (media) => {
  if (!media) return;
  let list = [];
  switch (media.type) {
    case "Movie":
      list = media.aggregatedVideo?.genreEntityList || media.genreEntityList;
      break;
    case "Season":
      const season =
        props?.media?.tvShowSeason || props?.media?.tvShowSeasons?.[0];
      list = season?.genreEntityList || season?.genres;
      break;
    case "Series":
      list = media.tvShowSeries?.genres;
      if (media.contentType == "Podcast") {
        list = media.genreEntityList;
      }
      break;
    default:
      list = media.genreEntityList || [];
      break;
  }

  const output = list?.filter((l) => {
    const isNotMajidSong = l?.externalId != "Majid Songs";
    const isNotBlank = l.name.trim().length > 0;

    return isNotMajidSong && isNotBlank;
  });

  return output?.map((g) => g.name);
};

const getContentId = () => {
  const watchStore = useWatchStore();
  switch (watchStore.media.type) {
    case "Movie":
      return watchStore.media?.aggregatedVideo?.assetExternalId;
    case "Season":
    case "Series":
      return watchStore?.currentEpisode?.assetExternalId;

    default:
      break;
  }
};

const getRadioContentId = () => {
  const radioStore = useRadioStore();
  if (!radioStore.widgetStation.isPodcast) {
    return radioStore?.activeChannel?.id;
  } else {
    switch (radioStore?.widgetStation?.type) {
      case "Movie":
        return radioStore.widgetStation?.aggregatedVideo?.assetExternalId;
      case "Season":
      case "Series":
        return radioStore.widgetStation?.assetExternalId;

      default:
        break;
    }
  }
};
const getPrLevel = () => {
  const watchStore = useWatchStore();
  switch (watchStore.media.type) {
    case "Movie":
      return watchStore.media?.aggregatedVideo?.prLevel;
    case "Season":
    case "Series":
      return watchStore.currentEpisode?.prLevel;

    default:
      break;
  }
};
const getRadioPrLevel = () => {
  const radioStore = useRadioStore();
  if (!radioStore.widgetStation.isPodcast) {
    if (radioStore?.activeChannel?.programs?.[0]) {
      return radioStore?.activeChannel?.programs?.[0]?.prLevel;
    } else {
      return radioStore?.activeChannel?.prLevel;
    }
  } else {
    switch (radioStore?.widgetStation.type) {
      case "Movie":
        return radioStore?.widgetStation?.aggregatedVideo?.prLevel;
      case "Season":
      case "Series":
        return radioStore?.widgetStation?.prLevel;
    }
  }
};

const getRadioContentProvider = () => {
  const radioStore = useRadioStore();
  if (!radioStore.widgetStation.isPodcast) {
    if (radioStore?.activeChannel?.programs?.[0]) {
      return radioStore?.activeChannel?.programs?.[0]?.contentProvider;
    } else {
      return radioStore?.activeChannel?.contentProvider;
    }
  } else {
    switch (radioStore?.widgetStation.type) {
      case "Movie":
        return radioStore?.widgetStation?.aggregatedVideo?.contentProvider;
      case "Season":
      case "Series":
        return radioStore?.widgetStation?.contentProvider;
    }
  }
};

const getContentProvider = () => {
  const watchStore = useWatchStore();
  switch (watchStore.media.type) {
    case "Movie":
      return watchStore.media?.aggregatedVideo?.contentProvider;
    case "Season":
    case "Series":
      return watchStore.currentEpisode?.contentProvider;

    default:
      break;
  }
};

export default function () {
  return {
    parseBase: () => {
      const sessionStore = useSessionStore();

      const {
        public: {
          mdaApplicationName,
          mdaApplicationVersion,
          mdaDeviceOperator,
        },
      } = useRuntimeConfig();

      const base = {
        mdaApplicationName,
        mdaApplicationVersion,
        mdaDeviceOperator,
        sesSessionSerialId: 1,
        sesSessionAccountId: "adtvguest",
        sesSessionUserId: "guest",
      };

      if (sessionStore.isAuthenticated) {
        try {
          Object.assign(base, {
            sesSessionSerialId: sessionStore.identifiers?.serialNumber,
            sesSessionAccountId: sessionStore.account.externalId,
            sesSessionUserId: sessionStore.activeProfile?.external_id,
          });
        } catch (error) {}
      }

      return base;
    },
    parseRadioVod: () => {
      const radioStore = useRadioStore();
      return {
        sesSessionType: "radio",
        sesContentGenre: buildGenresList(radioStore?.widgetStation)?.join(","),
        sesContentId: getRadioContentId(),
        sesContentParentalRating: getRadioPrLevel(),
        sesContentProvider: getRadioContentProvider(),
        sesContentType: "Radio",
        sesContentName: radioStore?.widgetStation.isPodcast
          ? radioStore?.widgetStation?.aggregatedVideo?.name
          : radioStore?.activeChannel?.name,
      };
    },
    parseRadioSeries: () => {
      const radioStore = useRadioStore();
      return {
        sesSessionType: "radio",
        sesContentEpisodeNb:
          radioStore?.widgetStation?.tvShowReference?.episodeNumber,
        sesContentSeasonNb:
          radioStore?.widgetStation?.tvShowReference?.seasonNumber,
        sesContentGenre: buildGenresList(radioStore?.widgetStation)?.join(","),
        sesContentProvider: getRadioContentProvider(),
        sesContentName: radioStore?.widgetStation.isPodcast
          ? radioStore?.widgetStation?.displayName
          : radioStore?.activeChannel?.name,
        sesContentType: "Radio",
      };
    },
    parseVod: (media) => {
      const watchStore = useWatchStore();
      const gtmStore = useGTMStore();
      return {
        sesSessionType: SESSION_TYPES.VOD,
        sesContentGenre: buildGenresList(media)?.join(","),
        sesContentId: getContentId(),
        sesContentParentalRating: getPrLevel(),
        sesContentProvider: getContentProvider(),
        sesContentName: watchStore?.h1Label,
        sesContentType: gtmStore.mediaType,
      };
    },
    parseSeries: () => {
      const watchStore = useWatchStore();
      const gtmStore = useGTMStore();
      return {
        sesContentEpisodeNb:
          watchStore.currentEpisode?.tvShowReference?.episodeNumber,
        sesContentSeasonNb: watchStore.currentSeason?.seasonNumber,
        sesContentGenre: buildGenresList(watchStore.media)?.join(","),
        sesContentProvider: getContentProvider(),
        sesContentName: watchStore?.currentEpisode?.displayName,
        sesContentType: gtmStore.mediaType,
        sesSessionType: SESSION_TYPES.VOD,
      };
    },

    parseLive: (channel, program, isLive) => {
      return {
        sesContentName: program?.name || channel?.name,
        sesContentId: channel?.externalChannelId,
        sesSessionType: SESSION_TYPES.LIVE,
        sesContentParentalRating: program.prLevel,
        sesContentType: isLive ? "live" : "recorded",
        sesContentGenre: buildGenresList(program)?.join(","),
        sesContentProvider: program.contentProvider,
      };
    },

    parseSong: (song) => {
      return {
        sesContentGenre: buildGenresList(song)?.join(","),
        sesContentId: song.externalId,
        sesContentType: song.type,
        sesContentName: song.name,
      };
    },
  };
}
