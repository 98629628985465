import { defineStore } from "pinia";
const { secondsToTime, asDurationPretty } = useDateTime();

export const usePlayerStoreWithID = (id, useLocalPcw = false) =>
  defineStore(id, {
    state: () => ({
      player: null,
      playbackState: null,
      currentTime: null,
      totalDuration: null,
      isMuted: null,
      volume: 1,
      overbarWidth: null,
      isFullscreen: false,
      isPip: false,
      playbackRate: 1.0,
      qualities: [],
      quality: -1,
      hasThumbnails: false,
      errorCount: 0,
      useLocalPcw: useLocalPcw,

      // ad related states
      isAdPlayer: false,
      adBreak: null,
      currentAd: null,
      adCurrentTime: null,
      abortSignal: false,
    }),
    getters: {
      shouldAbort() {
        console.info("[🔺 shouldAbort]", this.abortSignal);
        return this.abortSignal;
      },

      elapsedTimeLabel() {
        return secondsToTime(this.currentTime);
      },
      totalTimeLabel() {
        const { secondsToTime } = useDateTime();
        const durationLabel = secondsToTime(this.totalDuration || 0);
        return `${durationLabel}`;
      },
      isPlaying() {
        return this.playbackState === "playing";
      },
      getSpeed() {
        return this.playbackRate;
      },
      indicatorPosition() {
        const position = this.currentTime / this.player?.duration;
        if (isNaN(position)) {
          return 0;
        }
        return position;
      },

      /**
       * returns true when the current time is around
       * the last ten percent of the total duration
       */
      isLastTen() {
        if (!this.currentTime) return false;
        const lastTenSec = this.totalDuration - 10;
        return this.currentTime > lastTenSec;
      },
    },
    actions: {
      /**
       * initialize the player on a given element id
       * might need to refactor this so the trailer, hero and other players
       * can use this feature.
       */
      async initialize(elementId) {
        const { $voplayer } = useNuxtApp();

        const {
          public: { voplayerLicense },
        } = useRuntimeConfig();

        this.player = await $voplayer.createPlayer(elementId);
        this.player.license = voplayerLicense;

        console.info(
          `[🕹️ PlayerStore] player v${this.player.version} initialized for`,
          elementId
        );
        this.registerEvents();
      },
      registerEvents() {
        if (!this.player) {
          console.error("player is invalid. no events to register to");
          return;
        }

        this.player.on("error", (e) => {
          console.error("PLAYER EVENT => error", e);
          this.errorCount++;
          onError(e);
        });

        this.player.on("warning", (e) => {
          console.error("PLAYER EVENT => warning", e);
        });

        this.player.on("playing", () => {
          this.playbackState = "playing";
        });

        this.player.on("pause", () => {
          this.playbackState = "paused";
        });

        this.player.on("ended", () => {
          if (sessionStorage && this.useLocalPcw) {
            sessionStorage.removeItem("local-pcw");
          }
          this.playbackState = "ended";
        });

        this.player.on("buffering", (e) => {
          this.playbackState = "buffering";
        });
        this.player.on("timeupdate", () => {
          this.currentTime = this.player.currentTime;
          this.playbackRate = this.player.playbackRate;

          if (sessionStorage && this.useLocalPcw) {
            sessionStorage.setItem(
              "local-pcw",
              JSON.stringify({
                identifier: location.href,
                viewDuration: this.currentTime,
              })
            );
          }

          if (this.shouldAbort) {
            this.player.destroy();
          }
        });
        this.player.on("durationchange", () => {
          this.totalDuration = this.player.duration;
        });
        this.player.on("loaded", () => {
          // Hack: Map index to id of quality VO player considers index order not the id
          this.qualities = this.player.qualities.map((quality, i) => {
            return { ...quality, id: i };
          });
          this.hasThumbnails = this.player.hasThumbnails();
        });
        this.player.on("qualitychanged", () => {
          this.quality = this.player.quality;
        });

        this.player.on("volumechange", () => {
          this.isMuted = this.player.video_.muted;
          this.volume = this.player.volume;
        });

        this.player.on("adBreakStarted", (e) => {
          this.adBreak = e;
          this.playbackState = "adBreakStarted";
          this.isAdPlayer = true;
        });

        this.player.on("adStarted", (e) => {
          this.currentAd = e;
          this.playbackState = "adStarted";
          this.isAdPlayer = true;
        });

        this.player.on("adEnded", () => {
          this.playbackState = "adEnded";
          this.isAdPlayer = false;
        });

        this.player.on("adplay", () => {
          this.playbackState = "adplay";
        });

        this.player.on("adpause", () => {
          this.playbackState = "adpause";
        });

        this.player.on("adBreakEnded", () => {
          this.isAdPlayer = false;
          this.playbackState = "adBreakEnded";
          this.adCurrentTime = 0;
          this.adBreak = null;
          this.currentAd = null;
          this.adCurrentTime = null;
        });

        this.player.on("adTimeUpdated", (e) => {
          this.adCurrentTime = e.currentTime;

          if (this.shouldAbort) {
            this.player.destroy();
          }
        });

        // Video event listener
        this.player.videoElement.addEventListener("loadeddata", function () {});
      },
      /**
       * func loadMedia()
       * @param media = {
          url: null,
          startTime: 0,
        }
       */
      async loadMedia(
        media,
        extraOpts = {},
        adConfig = {},
        analyticsConfig = {}
      ) {
        if (!media?.url) return;

        await this.player?.reset();

        // Create Base Media
        let baseMediaObj = {
          url: media.url,
          startTime: media?.position,
        };

        /**
         * special case: if the user refreshes the page,
         * this function is called, which then fetches PCW for the view duration
         * which has the tendency to be a few seconds (max 10) off.
         * check the session storage for a "local-pcw"
         * and see if the stored identifier matches the current one (using location.href)
         * if value exists, take that and store as the final value of media.position
         */
        if (sessionStorage && this.useLocalPcw) {
          const localPcwValue = JSON.parse(sessionStorage.getItem("local-pcw"));
          if (localPcwValue && localPcwValue.identifier == location.href) {
            baseMediaObj.startTime = localPcwValue.viewDuration;
          }
        }

        if (adConfig && adConfig?.url) {
          if (adConfig.protocol === "VAST") {
            baseMediaObj.vastUrl = adConfig.url;
          } else if (adConfig.protocol === "VMAP") {
            baseMediaObj.vmapUrl = adConfig.url;
          } else {
            baseMediaObj.mastUrl = adConfig.url;
          }
        }

        const config = {
          autostart: true, // navigator.userActivation?.hasBeenActive || false,
          forceAppleNative: true,
          iosPlaysInline: true,
          ads: {
            loadVastWithVmap: false,
          },
        };

        const isLive = this.player?.isLive();

        if (Object.keys(media).includes("token") && media.token.length > 0) {
          console.info("[🐥] pre-drm aborted", this.abortSignal?.aborted);
          if (this.shouldAbort) {
            // Handle cancellation gracefully
            return;
          }
          config.drm = await useConfig().generateDrm(media.token, isLive);

          // this.player.registerLicenseRequestFilter((request) =>
          //   useVOfuncs().fairplayLice nseRequestFilter(request)
          // );
          // this.player.registerLicenseResponseFilter((response) =>
          //   useVOfuncs().fairplayLicenseResponseFilter(response)
          // );
        }

        await nextTick();

        config.streaming = {};

        config.applicationAnalytics = {
          ...usePsrConfig().parseBase(),
          ...analyticsConfig,
        };

        if (this.shouldAbort) return;

        this.player.configure(config);

        console.info("[🕹️ PlayerStore] configured with", {
          config: config,
          playerConfig: this.player.getConfiguration(),
          adConfig: adConfig,
        });
        await nextTick();

        try {
          console.info("[🐥] pre-load aborted", this.abortSignal?.aborted);
          if (this.shouldAbort) {
            // Handle cancellation gracefully
            return;
          }

          await this.player.load(baseMediaObj);
          console.info("[🕹️ PlayerStore] loaded media with ", baseMediaObj);
          await nextTick();
        } catch (error) {
          console.error("Player load error: ", error);
        }
      },

      play() {
        this.player?.play();
        this.playbackState = "playing";
      },

      pause() {
        this.player?.pause();
        this.playbackState = "paused";
      },

      togglePlayback() {
        if (!this.player) return;
        if (this.player?.playing_) {
          this.pause();
        } else {
          this.play();
        }
      },

      scrubForward() {
        const jumpTime = this.currentTime + 10;
        this.currentTime = Math.min(jumpTime, this.player.duration);
        this.player.currentTime = this.currentTime;
      },

      scrubBack() {
        const jumpTime = this.currentTime - 10;
        this.currentTime = Math.max(jumpTime, 0);
        this.player.currentTime = this.currentTime;
      },
      scrubToSec(sec) {
        this.currentTime = sec;
        this.player.currentTime = sec;
      },
      scrubTo(relX) {
        const clickedSeconds = this.calcRelXToSeconds(relX);

        this.currentTime = clickedSeconds;
        this.player.currentTime = clickedSeconds;
      },
      stepVolume(increment) {
        const newVolume = this.player.volume + increment;
        this.player.volume = Math.max(0, Math.min(1, newVolume));
      },
      setVolume(value) {
        this.player.volume = value;
      },
      toggleMute() {
        this.isMuted = !this.isMuted;
        this.player.volume = this.isMuted ? 0 : 1;

        this.player.video_.muted = this.isMuted;
      },
      setSpeed(num) {
        this.player.playbackRate = num;
      },
      setQuality(qualityId) {
        // this will set player.textTracks[trackId] as the active track.
        // ABR is -1;
        this.player.quality = qualityId;
      },
      setAudioTrack(trackId) {
        this.player.audioTrack = trackId;
      },
      setSubtitle(trackId) {
        // -1: disable
        this.player.textTrack = trackId;
      },
      getThumbnail(relX) {
        if (!this.hasThumbnails) return null;
        const calcTime = this.calcRelXToSeconds(relX);
        return this.player.getThumbnail(calcTime);
      },
      /**
       * first get ratio of seconds in relation to the width of the bar
       * then based on the relative x click event, calculate the number
       * of seconds corresponding to the click position on the bar
       * @param {int} relX: clickEvent.clientX
       */
      calcRelXToSeconds(relX) {
        const secondsPerPixel = this.player.duration / this.overbarWidth;
        return relX * secondsPerPixel;
      },

      toggleFullscreen() {
        this.isFullscreen = !this.isFullscreen;

        const wrapperEl = document.querySelector(`#${id}-container`);
        if (this.isFullscreen) {
          if (wrapperEl.requestFullscreen) {
            wrapperEl.requestFullscreen();
          } else if (wrapperEl.webkitRequestFullscreen) {
            /* Safari */
            const video = wrapperEl.getElementsByTagName("video");
            video?.[0].webkitEnterFullScreen();
          } else if (wrapperEl.msRequestFullscreen) {
            /* IE11 */
            wrapperEl.msRequestFullscreen();
          }
        } else {
          useExitFullscreen(wrapperEl);
        }

        this.player.signalFullscreen(this.isFullscreen);
      },
      listenToPipExit() {
        console.log("Pip Removed");
        this.isPip = false;
        this.player?.video_.removeEventListener(
          "leavepictureinpicture",
          this.listenToPipExit
        );
      },
      requestPip() {
        this.isPip = !this.isPip;
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture();
        } else if (document.pictureInPictureEnabled) {
          this.player?.video_.requestPictureInPicture();
          this.player?.video_.addEventListener(
            "leavepictureinpicture",
            this.listenToPipExit
          );
        }
      },
      /**
       * wrapper for player.configure as to not overwrite current configuration
       * @param {*} key
       * @param {*} payload
       */
      updatePlayerConfig(key, payload) {
        const currentConfig = this.player.getConfiguration();
        const currentConfigValue = currentConfig[key];

        const newConfig = { ...currentConfig };
        newConfig[key] = {
          ...currentConfigValue,
          ...payload,
        };

        this.player.configure(newConfig);

        console.info(
          "[🕹️ PlayerStore] added configuration",
          this.player.getConfiguration()
        );
      },

      removeLocalPcwKey() {
        if (sessionStorage && this.useLocalPcw) {
          sessionStorage.removeItem("local-pcw");
        }
      },
    },
  });
